import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";
import { objectToSearchParams } from '../utils/helpers/common';

export const userSignupServiceApi = createApi({
  reducerPath: "userSignupService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getUsersStats: builder.query({
      query: ({ data }) => ApiSlug.GET_USERS_STATS,
      providesTags: ["stats"]
    }),

    getUsers: builder.query({
      query: ({ data }) => `${ApiSlug.GET_ALL_SIGNUP_USERS}?${objectToSearchParams(data)}`,
      providesTags: ["users"]
    }),

    getSingleUser: builder.query({
      query: (id) => ApiSlug.GET_SINGLE_USER.replace("{id}", id),
      providesTags: ["users"]
    }),

    verifyUserEmail: builder.mutation({
      query: (data) => ({
        url: ApiSlug.VERIFY_EMAIL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    verifyUserPhone: builder.mutation({
      query: (data) => ({
        url: ApiSlug.VERIFY_PHONE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    initUser: builder.mutation({
      query: (data) => ({
        url: ApiSlug.INIT_USER_SIGNUP,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    generateMobileOTP: builder.mutation({
      query: (data) => ({
        url: ApiSlug.GENERATE_MOBILE_OTP,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    personalInfoSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.PERSONAL_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    residentialInfoSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.RESIDENTIAL_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    bankInfoSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.BANK_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    employmentInfoSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.EMPLOYMENT_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    politicalInfoSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.POLITICAL_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    filesSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.FILES_INFO_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    termsConditionsSave: builder.mutation({
      query: (data) => ({
        url: ApiSlug.TERMS_CONDITIONS_SAVE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["users"]
    }),

    userEdit: builder.mutation({
      query: (data) => ({
        url: ApiSlug.EDIT_USER_SAVE,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["single_user"]
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useInitUserMutation,
  useVerifyUserEmailMutation,
  useLazyGetUsersStatsQuery,
  usePersonalInfoSaveMutation,
  useResidentialInfoSaveMutation,
  useEmploymentInfoSaveMutation,
  usePoliticalInfoSaveMutation,
  useBankInfoSaveMutation,
  useFilesSaveMutation,
  useTermsConditionsSaveMutation,
  useLazyGetSingleUserQuery,
  useUserEditMutation,
  useGenerateMobileOTPMutation,
  useVerifyUserPhoneMutation
} = userSignupServiceApi;
