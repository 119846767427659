
export const objectToSearchParams = (obj: any, ignore: string[] = []) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!ignore.includes(key)) {
        params.append(key, obj[key]);
      }
    }
  }

  return params.toString();
};

export function capitalizeWords(value: string) {
  return value
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

//  function base64ToBlob(base64Data: any) {
//   const contentType = base64Data.match(/^data:([^;]+);base64,/)[1];
//   const base64WithoutPrefix = base64Data.split(',')[1];
//   const bytes = atob(base64WithoutPrefix);
//   const array = new Uint8Array(bytes.length);
//   for (let i = 0; i < bytes.length; i++) {
//     array[i] = bytes.charCodeAt(i);
//   }
//   return new Blob([array], { type: contentType });
// }

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  console.log(base64WithoutPrefix)

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};