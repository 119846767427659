import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { UrlSlugType } from "../utils/enums/UrlSlug";

// Layout
const AuthLayout = React.lazy(() => import("../layouts/Auth"));

// pages
const LoginPage = React.lazy(() => import("../pages/Public/Login"));
const ForgotPasswordPage = React.lazy(
  () => import("../pages/Public/ForgotPassword")
);
const ResetPasswordPage = React.lazy(
  () => import("../pages/Public/ResetPassword")
);
const SignUpPage = React.lazy(
  () => import("../pages/Public/Signup")
);

const PublicRoutes = () =>
  useRoutes([
    {
      element: <AuthLayout />,
      children: [
        { path: UrlSlugType.LOGIN, element: <LoginPage /> },
        { path: UrlSlugType.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
        { path: UrlSlugType.RESET_PASSWORD, element: <ResetPasswordPage /> },
      ],
    },
    { path: UrlSlugType.SIGNUP_USER, element: <SignUpPage /> },
    { path: "*", element: <Navigate to={UrlSlugType.SIGNUP_USER} /> },
  ]);

export default PublicRoutes;
