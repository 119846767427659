import { useEffect, useMemo } from 'react';
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "./store/slices/auth.slice";
import PublicRoutes from "./routes/PublicRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import { useLoginMutation } from "./services/authService";

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess } = useLoginMutation({
    fixedCacheKey: "auth-login",
  })[1];

  const token = useSelector((state: any) => state.auth.token);
  
  const Routes = useMemo(() => {
    if (!token) {
      return <PublicRoutes />;
    }
    return <AdminRoutes />;
  }, [token]);

  useEffect(() => {
    isSuccess && dispatch(setAuth(data?.data));
  }, [isSuccess, data, dispatch]);
  
  return (
    <BrowserRouter children={Routes} />
  );
}

export default App;
