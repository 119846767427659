import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { Container, Content, HamburgerMenu } from "./styles";
import Sidebar from "./Sidebar";

const PrivateLayout = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerCose = useCallback(() => setOpen(false), []);

  return (
    <Container>
      <Sidebar isDrawerOpen={open} handleDrawerClose={handleDrawerCose} />
      <Content>
        <HamburgerMenu onClick={handleDrawerOpen} />
        <Outlet />
      </Content>
    </Container>
  );
};

export default PrivateLayout;