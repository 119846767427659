import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import localStorage from "redux-persist/lib/storage";
import authReducer from "./slices/auth.slice";

// Services
import { authServiceApi } from "../services/authService";
import { userSignupServiceApi } from "../services/userSignupService";
import { publicServiceApi } from "../services/publicService";


const rootConfig = {
  key: "cds_web_signup",
  storage: localStorage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(
  rootConfig,
  combineReducers({
    auth: authReducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [userSignupServiceApi.reducerPath]: userSignupServiceApi.reducer,
    [publicServiceApi.reducerPath]: publicServiceApi.reducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authServiceApi.middleware,
      userSignupServiceApi.middleware,
      publicServiceApi.middleware,
    ] as any),
});

setupListeners(store.dispatch);

export const persistedStore = persistStore(store);
