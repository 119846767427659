import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./config/theme";
import Splash from "./components/core/mui/Splash";
import { store, persistedStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Notifier } from "./components/core/Notify";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<Splash />}>
          <CssBaseline />
          <Notifier />
          <App />
        </React.Suspense>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
