import { styled, Box, BoxProps } from "@mui/material";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100vw",
  height: "100vh",
  backgroundColor: "#F7F7F7",
  [theme.breakpoints.down("md")]: {
    backgroundColor: "#ffffff",
  },
}));

export const Content = styled((props: BoxProps) => (
  <Box
    position='relative'
    width='100%'
    height='100vh'
    p={{ xs: "15px", md: "46px" }}
    {...props}
  />
))({ display: "flex", flexFlow: "column", overflow: "auto" });

export const HamburgerMenu = styled((props: BoxProps) => (
  <Box display={{ md: "none" }} {...props}>
    <MenuIcon />
  </Box>
))({
  position: "absolute",
  top: "17px",
  left: "15px",
  cursor: "pointer",
});