import { Box } from "@mui/material";
import Logo from "../../../assets/icons/logo.png";

const Splash = () => {
  return (
    <Box width="100%" height="100vh" display="flex">
      <Box m="auto">
        <img src={Logo} alt="Logo" style={{ maxWidth: "220px" }} />
      </Box>
    </Box>
  );
};

export default Splash;
