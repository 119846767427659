import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { UrlSlugType } from '../utils/enums/UrlSlug';
import DashboardLayout from "../layouts/Private";

// admin related pages
const HomePage = React.lazy(
  () => import("../pages/Private/Home")
);
const ApprovedUserPage = React.lazy(
  () => import("../pages/Private/ApprovedUser")
);
const StoredUserPage = React.lazy(
  () => import("../pages/Private/StoredUser")
);
const InitialUserPage = React.lazy(
  () => import("../pages/Private/InitialUser")
);
const SignUpPage = React.lazy(
  () => import("../pages/Public/Signup")
);
const SingleUserPage = React.lazy(
  () => import("../pages/Private/SingleUser")
);


const AdminPrivateRoutes = () =>
  useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        { path: UrlSlugType.Home, element: <HomePage /> },
        { path: UrlSlugType.INITIAL_USER, element: <InitialUserPage /> },
        { path: UrlSlugType.STORED_USER, element: <StoredUserPage /> },
        { path: UrlSlugType.APPROVED_USER, element: <ApprovedUserPage /> },
        { path: UrlSlugType.SINGLE_USER, element: <SingleUserPage /> },
      ],
    },
    { path: UrlSlugType.SIGNUP_USER, element: <SignUpPage /> },
    { path: "*", element: <Navigate to={UrlSlugType.Home} /> },
  ]);

export default AdminPrivateRoutes;
