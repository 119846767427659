import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/users.svg";
import { UrlSlugType } from '../../../utils/enums/UrlSlug';

const MenuItems = () => {
  const { pathname } = useLocation();

  const dynamicIconColorChange = useCallback(
    (path: string) => ({ stroke: pathname.includes(path) ? "#ffffff" : "  #7a86a1", fill: pathname.includes(path) ? "#ffffff" : "  #7a86a1" }),
    [pathname]
  );

  return [
    {
      name: "Home",
      path: UrlSlugType.Home,
      icon: <DashboardIcon style={dynamicIconColorChange("/home")} />,
      users: ['admin'],
    },
    {
      name: "Incomplete Applications",
      path: UrlSlugType.INITIAL_USER,
      icon: <UsersIcon style={dynamicIconColorChange("/init-user")} />,
      users: ['admin'],
    },
    {
      name: "Complete Applications",
      path: UrlSlugType.STORED_USER,
      icon: <UsersIcon style={dynamicIconColorChange("/pending-user")} />,
      users: ['admin'],
    },
    {
      name: "Approved Applications",
      path: UrlSlugType.APPROVED_USER,
      icon: <UsersIcon style={dynamicIconColorChange("/approved-user")} />,
      users: ['admin'],
    },
  ];
};

export default MenuItems;
