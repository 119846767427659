import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Container, MenuItem, SignOutButton } from "./styles";
import { ReactComponent as LogoIcon } from "../../../assets/icons/users.svg";
import { ReactComponent as SignOutIcon } from "../../../assets/icons/sign-out.svg";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../store/slices/auth.slice";
import { authServiceApi } from '../../../services/authService';

type SideBarProps = {
  isDrawerOpen: boolean;
  handleDrawerClose: () => void;
};

const Sidebar = ({ isDrawerOpen, handleDrawerClose }: SideBarProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  const isSmallDevice = useMediaQuery(useTheme().breakpoints.down("md"));

  const isLocationActive = useCallback(
    (params: string) => {
      return  pathname.includes(params);
    },
    [pathname]
  );
  const handleLinkButtonClick = useCallback(
    (path: string) => {
      navigate(path);
      handleDrawerClose();
    },
    [navigate, handleDrawerClose]
  );

  const handleLogOut = useCallback(() => {
    dispatch(authServiceApi.util.resetApiState());
    dispatch(logOut());
  }, [dispatch]);

  const content = (
    <Container>
      <LogoIcon width='82.8px' height='18px' />
      <Box
        width='100%'
        display='flex'
        flexDirection='row'
        gap={"13px"}
        // mt={"30px"}
      >
        <Box width={'100%'} textAlign={'center'}>
          {/* <Typography variant='subtitle2' lineHeight='18px' color='#1D3557' textTransform={'capitalize'}>
            {user?.username}
          </Typography> */}
          {/* <Typography
            variant='overline'
            lineHeight='10px'
            sx={{ opacity: "50%" }}
            color='#1D3557'
            
          >
            {user?.email ?? ""}
          </Typography> */}
          {/* <Typography sx={{ opacity: "50%" }} color='#1D3557' fontSize={"12px"} fontWeight={300}>
            {user.role === 'admin' && "Admin"}
          </Typography> */}
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={"10px"}
        mt={"42px"}
        flexGrow={1}
      >
        {MenuItems()
          .filter((route: any) => route.users.some((u: string) => u === user.role))
          .map((route: any, i: number) => (
            <MenuItem
              key={`${route.name}-${i}`}
              isActive={isLocationActive(route.path.toLowerCase())}
              onClick={() => handleLinkButtonClick(route.path)}
            >
              {route.icon}
              {route.name}
            </MenuItem>
          ))}
      </Box>
      <Divider flexItem light sx={{ mb: "18.5px", mx: "35px" }} />
      <SignOutButton variant='text' startIcon={<SignOutIcon />} onClick={handleLogOut}>
        Sign out
      </SignOutButton>
    </Container>
  );

  return isSmallDevice ? (
    <Drawer
      variant='temporary'
      open={isDrawerOpen}
      anchor='left'
      onClose={handleDrawerClose}
    >
      {content}
    </Drawer>
  ) : (
    content
  );
};

export default Sidebar;
