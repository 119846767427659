export enum ApiSlug {
  // Auth
  LOGIN = "/auth/login",
  REFRESH_TOKEN = "/auth/refresh-token",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  
  // Signup User
  INIT_USER_SIGNUP = "/sign-up/init",
  VERIFY_EMAIL = "/sign-up/verify-email",
  GENERATE_MOBILE_OTP = "/sign-up/generate-otp",
  VERIFY_PHONE = "/sign-up/verify-phone",
  PERSONAL_INFO_SAVE = "/sign-up/personal",
  RESIDENTIAL_INFO_SAVE = "/sign-up/residential",
  BANK_INFO_SAVE = "/sign-up/bank",
  EMPLOYMENT_INFO_SAVE = "/sign-up/employment",
  POLITICAL_INFO_SAVE = "/sign-up/political",
  FILES_INFO_SAVE = "/sign-up/files",
  TERMS_CONDITIONS_SAVE = "/sign-up/terms-conditions",
  EDIT_USER_SAVE = "/sign-up/edit",

  SIGNUP_USER = "/sign-up",

  GET_USERS_STATS = "/sign-up/stats",
  INIT_SIGNUP_USER = "/sign-up/init",
  GET_ALL_SIGNUP_USERS = "/sign-up/users",
  GET_SINGLE_USER = "/sign-up/user/{id}",
  APPROVE_SIGNUP_USER = "/sign-up/save-user",

  // Public API
  GET_BANKS = "/public/banks",
  GET_BANK_BRANCHES = "/public/bank-branch",
};
